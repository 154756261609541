import { Formik } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import { toSnakeCase } from '../../../../lib/keyFormatConverter';
import {
  useGetAccountSettingsQuery,
  useToggleAccountOptOutFeatureMutation,
  useToggleSsoOnlyAccountMutation,
  useUpdateAccountDataMutation,
  useUpdateAccountDefaultSettingsMutation,
  useUpdateCurriculumDefaultSettingsMutation,
} from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import LinkTabs, { LinkTab } from '../../../design_system/navigation/LinkTabs';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import { routes } from '../../publicApplication/applicationRouter';
import useLocalStorageState from '../../publicApplication/utils/useLocalStorageState';
import { SpecializationFormValue } from '../ResponsibilitySpecializations/types';
import { CUSTOM_TERMINOLOGY_MAX_CHARACTERS } from '../shared/constants/accountSettings';
import AccountSettingsForm from '../Tabs/Forms/AccountSettingsForm';
import { SettingsData } from '../Tabs/types';

const TabsWrapper = styled.nav`
  overflow: auto hidden;
  white-space: nowrap;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

const t = initTranslations('account_settings');

const settingsValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(t('errors.required'))
    .max(255, t('errors.too_long', { max: 255 })),
  phone: yup
    .string()
    .trim()
    .matches(/^(\+?\d{1,3}\s?)?(\(\d{3}\)\s?|\d{3}[-\s]?)\d{3}\s?-?\s?\d{4}$/, t('errors.invalid'))
    .nullable(),
  logoBackgroundColor: yup
    .string()
    .required(t('errors.required'))
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t('errors.invalid')),
  accentColor: yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t('errors.invalid')),
  customTerminology: yup.boolean(),
  companyTerm: yup.string().when('customTerminology', {
    is: true,
    then: yup
      .string()
      .max(
        CUSTOM_TERMINOLOGY_MAX_CHARACTERS,
        t('content_settings.custom_terminology_form.errors.max_characters_exceeded')
      ),
  }),
  policyTerm: yup.string().when('customTerminology', {
    is: true,
    then: yup
      .string()
      .max(
        CUSTOM_TERMINOLOGY_MAX_CHARACTERS,
        t('content_settings.custom_terminology_form.errors.max_characters_exceeded')
      ),
  }),
  processTerm: yup.string().when('customTerminology', {
    is: true,
    then: yup
      .string()
      .max(
        CUSTOM_TERMINOLOGY_MAX_CHARACTERS,
        t('content_settings.custom_terminology_form.errors.max_characters_exceeded')
      ),
  }),
  subjectTerm: yup.string().when('customTerminology', {
    is: true,
    then: yup
      .string()
      .max(
        CUSTOM_TERMINOLOGY_MAX_CHARACTERS,
        t('content_settings.custom_terminology_form.errors.max_characters_exceeded')
      ),
  }),
  topicTerm: yup.string().when('customTerminology', {
    is: true,
    then: yup
      .string()
      .max(
        CUSTOM_TERMINOLOGY_MAX_CHARACTERS,
        t('content_settings.custom_terminology_form.errors.max_characters_exceeded')
      ),
  }),
  stepTerm: yup.string().when('customTerminology', {
    is: true,
    then: yup
      .string()
      .max(
        CUSTOM_TERMINOLOGY_MAX_CHARACTERS,
        t('content_settings.custom_terminology_form.errors.max_characters_exceeded')
      ),
  }),
  responsibilityTerm: yup.string().when('customTerminology', {
    is: true,
    then: yup
      .string()
      .max(
        CUSTOM_TERMINOLOGY_MAX_CHARACTERS,
        t('content_settings.custom_terminology_form.errors.max_characters_exceeded')
      ),
  }),
});

type Prop = {
  tab?: string;
};

const AccountSettingsTabs = ({ tab }: Prop) => {
  const { delegationPlannerEnabled, slug, allowSignatures } = useCurrentAccount();
  const { data, isLoading: isLoadingData } = useGetAccountSettingsQuery();

  const [updateAccountData, result] = useUpdateAccountDataMutation();
  const [toggleAccountFeature, toggleResult] = useToggleAccountOptOutFeatureMutation();
  const [updateDefaultSettings, defaultSettingsResult] =
    useUpdateCurriculumDefaultSettingsMutation();
  const [updateAccountDefaultData, accountDefaultSettingsResult] =
    useUpdateAccountDefaultSettingsMutation();
  const [toggleSsoOnlyAccount, ssoResult] = useToggleSsoOnlyAccountMutation();

  const isLoading = useMemo(() => {
    return (
      result.isLoading ||
      toggleResult.isLoading ||
      defaultSettingsResult.isLoading ||
      accountDefaultSettingsResult.isLoading
    );
  }, [
    result.isLoading,
    toggleResult.isLoading,
    defaultSettingsResult.isLoading,
    accountDefaultSettingsResult.isLoading,
  ]);

  const [invalidateDPSpecializationsTags, setInvalidateDPSpecializationsTags] =
    useLocalStorageState({
      key: 'invalidateDPSpecializationsTags',
      initialValue: true,
    });

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  useDisplayFlashOnResponse({
    result: toggleResult,
    errorMessage: t('toggle_error_message'),
  });

  useDisplayFlashOnResponse({
    result: defaultSettingsResult,
    errorMessage: t('default_settings_error_message'),
  });

  useDisplayFlashOnResponse({
    result: accountDefaultSettingsResult,
    errorMessage: t('default_settings_error_message'),
  });

  useDisplayFlashOnResponse({
    result: ssoResult,
    errorMessage: t('default_settings_error_message'),
  });

  const specializations: SpecializationFormValue[] = useMemo(() => {
    if (!data) return [];

    return data.responsibilitySpecializations.map(({ id, terminology, color }) => ({
      id,
      terminology,
      color,
    }));
  }, [data]);

  if (!data || isLoadingData) return null;

  const {
    employeeSize,
    phone,
    name,
    industry,
    accentColor,
    accentPalette,
    logoUrl,
    logoBackgroundColor,
    brandStyles,
    defaultBrandStyles,
    advancedSettings,
  } = data;

  const initialValues = {
    name,
    industry,
    employeeSize,
    phone,
    ipWhitelist: advancedSettings.ipWhitelist,
    remoteIp: advancedSettings.remoteIp,
    restrictByIp: advancedSettings.restrictByIp,
    mfaOn: advancedSettings.mfaOn,
    accentColor,
    accentPalette,
    logoBackgroundColor,
    logoImageUrl: logoUrl,
    betaFeatures: advancedSettings.betaFeatures,
    ssoOnlyAccount: advancedSettings.ssoOnlyAccount,
    showModal: false,
    publicCurriculums: advancedSettings.publicCurriculums,
    enablePrinting: advancedSettings.enablePrinting,
    enablePeopleDirectory: advancedSettings.enablePeopleDirectory,
    groupsRestrictedFromDirectory: advancedSettings.groupsRestrictedFromDirectory,
    enableOrgChart: advancedSettings.enableOrgChart,
    groupsRestrictedFromOrgChart: advancedSettings.groupsRestrictedFromOrgChart,
    enableRoleChart: advancedSettings.enableRoleChart,
    groupsRestrictedFromRoleChart: advancedSettings.groupsRestrictedFromRoleChart,
    enableProfilePhoneNumber: advancedSettings.enableProfilePhoneNumber,
    enableProfileEmail: advancedSettings.enableProfileEmail,
    specializations,
    selectedPermissions: advancedSettings.signaturePermissions,
    defaultAccessControl: advancedSettings.defaultAccessControl,
    brandStyles,
    defaultBrandStyles,
    previousStyles: brandStyles,
    isResetStyle: false,
    lockBrandStyles: advancedSettings.lockBrandStyles,
    enableSectorLibraries: advancedSettings.sectorLibraries,
    companySector: advancedSettings.companySector,
    policySector: advancedSettings.policySector,
    contentPageDefaultViewBy: advancedSettings.contentPageDefaultViewBy,
    customTerminology: advancedSettings.customTerminology,
    companyTerm: advancedSettings.customTerminologies.companyTerm,
    policyTerm: advancedSettings.customTerminologies.policyTerm,
    processTerm: advancedSettings.customTerminologies.processTerm,
    subjectTerm: advancedSettings.customTerminologies.subjectTerm,
    stepTerm: advancedSettings.customTerminologies.stepTerm,
    topicTerm: advancedSettings.customTerminologies.topicTerm,
    responsibilityTerm: advancedSettings.customTerminologies.responsibilityTerm,
    pluralizeCompanyTerm: advancedSettings.customTerminologySettings.pluralizeCompanyTerm,
    pluralizeProcessTerm: advancedSettings.customTerminologySettings.pluralizeProcessTerm,
    pluralizeResponsibilityTerm:
      advancedSettings.customTerminologySettings.pluralizeResponsibilityTerm,
    pluralizeStepTerm: advancedSettings.customTerminologySettings.pluralizeStepTerm,
    pluralizePolicyTerm: advancedSettings.customTerminologySettings.pluralizePolicyTerm,
    pluralizeSubjectTerm: advancedSettings.customTerminologySettings.pluralizeSubjectTerm,
    pluralizeTopicTerm: advancedSettings.customTerminologySettings.pluralizeTopicTerm,
    allowSignatures,
  };

  const onSubmit = (value: SettingsData) => {
    const formattedValues = {
      ...toSnakeCase(value, {
        exclude: [
          'font-family',
          'font-size',
          'font-style',
          'font-weight',
          'text-align',
          'text-decoration',
          'border-color',
          'border-width',
        ],
      }),
      responsibility_specializations_attributes: value.specializations,
      signature_permissions: value.selectedPermissions,
      terminology_on: value.customTerminology,
    };

    if (advancedSettings.publicCurriculums !== value.publicCurriculums)
      toggleAccountFeature({ feature: 'public_curriculums' });

    if (advancedSettings.defaultAccessControl !== value.defaultAccessControl)
      updateDefaultSettings({ default_access_control: value.defaultAccessControl });

    if (advancedSettings.contentPageDefaultViewBy !== value.contentPageDefaultViewBy)
      updateAccountDefaultData({
        content_page_default_view_by: value.contentPageDefaultViewBy,
      });

    if (advancedSettings.sectorLibraries !== value.enableSectorLibraries)
      toggleAccountFeature({ feature: 'sector_libraries' });

    if (advancedSettings.companySector !== value.companySector)
      toggleAccountFeature({ feature: 'company_sector' });

    if (advancedSettings.policySector !== value.policySector)
      toggleAccountFeature({ feature: 'policy_sector' });

    if (advancedSettings.ssoOnlyAccount !== value.ssoOnlyAccount) toggleSsoOnlyAccount();

    updateAccountData(formattedValues).then(() =>
      setInvalidateDPSpecializationsTags(!invalidateDPSpecializationsTags)
    );
  };

  const GeneralTab: LinkTab = {
    name: t('tabs.general'),
    tabId: 'general-tab',
    is_beta: false,
    to: routes.accountSettings({ slug }),
  };

  const ContentTab: LinkTab = {
    name: t('tabs.content'),
    tabId: 'content-tab',
    is_beta: false,
    to: routes.accountSettings({ slug, tab: 'content' }),
  };

  const PeopleTab: LinkTab = {
    name: t('tabs.people'),
    tabId: 'people-tab',
    is_beta: false,
    to: routes.accountSettings({ slug, tab: 'people' }),
  };

  const DelegationTab: LinkTab = {
    name: t('tabs.delegation'),
    tabId: 'delegation-tab',
    is_beta: false,
    to: routes.accountSettings({ slug, tab: 'delegation' }),
  };

  const tabs = [GeneralTab, ContentTab, PeopleTab];

  if (delegationPlannerEnabled && !!data.responsibilitySpecializations.length)
    tabs.push(DelegationTab);

  return (
    <>
      <PageHeader title={t('settings')} />
      <TabsWrapper id='account-settins-tabs-wrapper'>
        <LinkTabs id='account-settins-tabs' isUrlParams tabs={tabs} />
      </TabsWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={settingsValidationSchema}
      >
        <AccountSettingsForm activeTab={tab} isLoading={isLoading} />
      </Formik>
    </>
  );
};

export default AccountSettingsTabs;
