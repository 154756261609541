import { Form, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../publicApplication/applicationRouter';
import ActiveSettingsTab from '../ActiveSettingsTab';
import { SaveButtonWrapper } from '../styles';
import { SettingsData } from '../types';

const t = initTranslations('account_settings.general_settings');

type Props = {
  activeTab?: string;
  isLoading: boolean;
};

const AccountSettingsForm = ({ activeTab, isLoading }: Props) => {
  const { values, initialValues, errors, handleSubmit } = useFormikContext<SettingsData>();
  const { slug } = useCurrentAccount();

  const handleErrorNavigation = useCallback(() => {
    if (errors.name || errors.phone || errors.logoBackgroundColor || errors.accentColor) {
      routes.accountSettings({ slug }).push();
    }
  }, [errors.accentColor, errors.logoBackgroundColor, errors.name, errors.phone, slug]);

  const isValuesUnchanged = isEqual(values, initialValues);

  const handleBeforeUnload = useCallback(
    (event) => {
      if (!isValuesUnchanged) {
        event.preventDefault();
        return (event.returnValue = '');
      }
    },
    [isValuesUnchanged]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [handleBeforeUnload]);

  return (
    <Form
      autoComplete='off'
      onSubmit={(values) => {
        handleSubmit(values);
        handleErrorNavigation();
      }}
    >
      <ActiveSettingsTab activeTab={activeTab} />
      <SaveButtonWrapper id='account-settings-save-wrapper'>
        <DefaultButton
          disabled={isValuesUnchanged}
          id='save-account-settings-button'
          loading={isLoading}
          text={t('save')}
        />
      </SaveButtonWrapper>
    </Form>
  );
};

export default AccountSettingsForm;
