import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';

import { PaywallContext } from '../../../../../../contexts/PaywallContext';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { useRedirectToBillingOverlay } from '../../../../../../hooks/useRedirectToBillingOverlay';
import { deleteQueryParam } from '../../../../../../lib/getQueryParams';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import ColorPickerInput from '../../../../../design_system/ColorPicker/ColorPickerInput';
import Hoverable from '../../../../../design_system/Hoverable';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import useActiveModalHandler from '../../../../../design_system/useActiveModalHandler';
import ImageUpload from '../../../../image_upload/ImageUpload';
import PaywallTooltip from '../../../../shared/tooltips/PaywallTooltip';
import PalettePicker from '../../../PalettePicker';
import { OFFERED_ACCENT_PALETTES } from '../../../shared/constants/accountSettings';
import { InputRow, StyledFormGroup } from '../../styles';
import { SettingsData } from '../../types';
import { ImageContainer, LogoContainer, LogoWrapper, Title } from './styles';
import { LogoUpdateProps } from './types';

const t = initTranslations('account_settings.general_settings');

const OrganizationLogo = ({ logoUrl, defaultLogoUrl }: LogoUpdateProps) => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<SettingsData>();

  const { activeModal, setActiveModal, closeActiveModal } = useActiveModalHandler();
  const queryParams = new URLSearchParams(window.location.search);
  const displayUpdateLogoModal = queryParams.get('show_update_logo_modal') === 'true';
  const navigateToBillingOverlay = useRedirectToBillingOverlay();

  useEffect(() => {
    if (displayUpdateLogoModal) {
      setActiveModal('update-organization-logo-modal');
      deleteQueryParam('show_update_logo_modal');
    }
  }, [displayUpdateLogoModal, setActiveModal]);

  const paywallCtx = useContext(PaywallContext);
  const brandStylesLocked = paywallCtx.includes('brand_styles');

  const [updatedLogo, setUpdatedLogo] = useState({ avatar: logoUrl });
  const [isToggleHovered, setIsToggleHovered] = useState(false);

  const [updateLogoData, result] = useUpdateAccountDataMutation();

  const { isLoading } = result;

  const updateLogo = () => {
    const formData: FormData = new FormData();
    formData.append('logo', updatedLogo.avatar);
    updateLogoData(formData);
  };

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
    successFunction: () => {
      closeActiveModal();
      result.reset();
    },
    errorMessage: t('logo_error_message'),
  });

  const taskModalArgs: TaskModalProps = {
    title: t('update_logo'),
    onCloseRequest: closeActiveModal,
    processing: isLoading,
    primaryButtonText: t('logo_modal.update_logo'),
    primaryButtonTask: updateLogo,
    secondaryButtonText: t('logo_modal.cancel'),
    desktopSize: 'lg',
    heapModalName: 'logo-update-modal',
  };

  return (
    <>
      <PaywallTooltip
        description={t('lock_brand_styles_paywall.description')}
        shouldBeVisible={isToggleHovered && brandStylesLocked}
        title={t('lock_brand_styles_paywall.title')}
      />
      <Hoverable setIsHovered={setIsToggleHovered}>
        <Title>{t('organization_logo')}</Title>
        <LogoContainer>
          <ImageContainer>
            <LogoWrapper logoBackgroundColor={values.logoBackgroundColor}>
              <img alt={t('logo')} src={logoUrl} />
            </LogoWrapper>
          </ImageContainer>
          {brandStylesLocked ? (
            <DefaultButton
              buttonType='secondary'
              iconName='lock'
              id='show-update-logo-modal-button'
              onClick={() => navigateToBillingOverlay()}
              text={t('update_logo')}
            />
          ) : (
            <DefaultButton
              buttonType='primary'
              id='show-update-logo-modal-button'
              onClick={() => setActiveModal('update-organization-logo-modal')}
              text={t('update_logo')}
              type='button'
            />
          )}
        </LogoContainer>

        <InputRow>
          <StyledFormGroup>
            <Title>{t('logo_background_color')}</Title>
            <ColorPickerInput
              color={values.logoBackgroundColor}
              disabled={brandStylesLocked}
              hasSelectionButton={false}
              onChange={(color: string) => {
                setFieldValue('logo_background_color', color);
              }}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <Title>{t('accent_color')}</Title>
            <PalettePicker
              ariaLabel={t('accent_color')}
              buttonId='brand-styles-color-picker-button'
              disabled={brandStylesLocked}
              errorText={(touched.accentColor && errors.accentColor) || undefined}
              hex={values.accentColor}
              id='color-picker-accent-color'
              name='accentColor'
              onChange={(e) => handleChange(e)}
              palette={values.accentPalette}
              palettes={OFFERED_ACCENT_PALETTES}
              setColor={(color: string) => {
                const isHex = color.includes('#');
                if (isHex) {
                  setFieldValue('accentColor', color);
                  setFieldValue('accentPalette', null);
                } else if ((OFFERED_ACCENT_PALETTES as string[]).includes(color)) {
                  setFieldValue('accentPalette', color);
                }
              }}
              type='background-color'
            />
          </StyledFormGroup>
        </InputRow>
      </Hoverable>

      {activeModal === 'update-organization-logo-modal' && (
        <TaskModal {...taskModalArgs}>
          <ImageUpload
            backgroundImageColor={values.logoBackgroundColor}
            backgroundImageDefault={defaultLogoUrl}
            imageForm='rectangle'
            imageSize='lg'
            imageUrl={logoUrl}
            resetButtonText={t('logo_modal.remove_logo')}
            setImage={setUpdatedLogo}
            text={t('logo_modal.we_suggest_using_a_transparent_png_file')}
            title={t('logo_modal.update_logo')}
            uploadButtonText={t('logo_modal.update_logo')}
          />
        </TaskModal>
      )}
    </>
  );
};

export default OrganizationLogo;
