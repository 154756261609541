import { skipToken } from '@reduxjs/toolkit/query';
import { truncate } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import TrainualLogo from '../../../../../../../assets/images/trainual-purple.png';
import usePublicConfigs from '../../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../../lib/initTranslations';
import { useUniversalLoginQuery } from '../../../../../redux/services/resourceApis/publicApplication/loginApi';
import { UniversalLoginResponse } from '../../../../../redux/services/resourceApis/publicApplication/types';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import BaseLine from '../../../../design_system/Triage/layout/Line';
import Loader from '../../../../design_system/Triage/Loader';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { fontSm4 } from '../../../../styled/TypeSystem';
import { routes } from '../../applicationRouter';
import PublicPageBase from '../../PublicPage';
import { FormWrapper, H1, Logo, LogoWrapper, StyledTitle } from '../../styles/shared';

const PublicPage = styled(PublicPageBase)`
  height: auto;
`;

const H2 = styled.h2`
  margin: 0;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  min-height: ${({ theme: { constants } }) => constants.heightLg};
  ${TruncatedText({})};
`;

const AccountName = styled.div`
  margin: ${({ theme: { constants } }) => constants.spacerMd1};
  ${TruncatedText({})};
`;

const AccountsList = styled.ul<{ newDesign?: boolean }>`
  display: grid;
  padding: 0;
  list-style: none;
  grid-template-columns: 1fr;
  gap: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg1}`};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg1}`};

  ${({ newDesign, theme: { constants } }) =>
    newDesign &&
    `
      gap: ${constants.spacerSm3};
      margin: 0;
      margin-top: ${constants.spacerMd2};
      min-height: 10rem;
      max-height: calc(100vh - 28rem);
      overflow: auto;
      ${fontSm4};
  `};

  @media (min-width: ${mediaBreakpointPxMd}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const AccountLogoWrapper = styled.div<{ background: string; newDesign?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ newDesign }) => (newDesign ? '8rem' : '9rem')};
  background: ${({ background }) => background};
`;

const AccountLogo = styled.img<{ newDesign?: boolean }>`
  max-width: 100%;
  max-height: 100%;

  ${({ newDesign }) => newDesign && `max-width: 70%; max-height: 60%;`}
`;

const AccountLink = styled.a<{ newDesign?: boolean; isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  place-items: center;
  border: ${({ theme, isSelected }) =>
    `${isSelected ? theme.constants.borderWidthXl : theme.constants.borderWidthSm}
      solid
      ${isSelected ? theme.vars.trainualBrandPurpleMedium : theme.vars.borderSurface2}
    `};
  box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
  text-decoration: none;
  color: ${({ theme: { vars } }) => vars.textDefault};
  overflow: hidden;

  ${({ newDesign, theme: { constants } }) =>
    newDesign && `border-radius: ${constants.borderRadiusXl}; padding: 0;`}

  &:hover {
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  }
`;

const Line = styled(BaseLine)<{ newDesign?: boolean }>`
  width: 100%;

  ${({ newDesign }) => newDesign && `margin: 0;`}
`;

type AccountProps = UniversalLoginResponse['users'][0] & {
  href: string;
  isSelected?: boolean;
  newDesign?: boolean;
  onSelect?: (url: string) => void;
  route: Route<typeof routes.accountSelection>;
};

const Account = ({
  account: { logo: logoUrl, name: accountName, logoBackgroundColor },
  href,
  isSelected,
  newDesign,
  onSelect,
}: AccountProps) => {
  const logo = useMemo(() => {
    if (!logoUrl || logoUrl.includes('trainual-purple')) return TrainualLogo;

    return logoUrl;
  }, [logoUrl]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute('href');
    url && onSelect && onSelect(url);
  };

  return (
    <li>
      {/* Navigation should be with page reload to get the current CSRF token (https://trainual.atlassian.net/browse/GS3-798) */}
      <AccountLink
        href={href}
        isSelected={isSelected}
        {...(newDesign ? { newDesign, onClick: handleClick } : {})}
      >
        <AccountLogoWrapper background={logoBackgroundColor} {...(newDesign && { newDesign })}>
          <AccountLogo src={logo} {...(newDesign && { newDesign })} />
        </AccountLogoWrapper>
        <Line {...(newDesign && { newDesign })} />
        {newDesign ? (
          <AccountName>{truncate(accountName, { length: 20 })}</AccountName>
        ) : (
          <H2>{truncate(accountName, { length: 20 })}</H2>
        )}
      </AccountLink>
    </li>
  );
};

const t = initTranslations('public_application.account_selector');

type Props = {
  route: Route<typeof routes.accountSelection>;
};

const AccountSelector = ({ route }: Props) => {
  const { configs } = usePublicConfigs();
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const universalLoginWithOtpEnabled = !!configs['UNIVERSAL_LOGIN_WITH_OTP'];
  const { isLoading, data } = useUniversalLoginQuery(
    route.params.email
      ? route.params.otp_token
        ? { email: route.params.email, otp_token: route.params.otp_token }
        : { email: route.params.email }
      : skipToken
  );

  useEffect(() => {
    if (!route.params.email || (data && data.users.length == 0)) routes.universalLogin().replace();
  }, [route.params.email, data]);

  const handleSelectAccount = (url: string) => {
    setSelectedAccount(url);
  };

  const handleButtonClick = () => {
    if (selectedAccount) {
      window.location.href = selectedAccount;
    }
  };

  const isAccountSelected = !!selectedAccount;

  const generateAccountHref = (
    user: UniversalLoginResponse['users'][0],
    route: Route<typeof routes.accountSelection>
  ) => {
    return routes.login({
      account_id: user.account.id,
      email: user.email,
      otp_token: user.otpToken || undefined,
      slug: user.account.slug,
      redirect_path: route.params.redirect_path,
    }).href;
  };

  return (
    <PublicPage id='account-selector-page'>
      {universalLoginWithOtpEnabled ? (
        <FormWrapper>
          <LogoWrapper height='auto'>
            <Logo src={TrainualLogo} />
          </LogoWrapper>
          <StyledTitle>{t('mfa.title')}</StyledTitle>
          <AccountsList newDesign>
            {isLoading ? (
              <Loader />
            ) : (
              data?.users?.map((user) => {
                const accountHref = generateAccountHref(user, route);

                return (
                  <Account
                    href={accountHref}
                    isSelected={selectedAccount === accountHref}
                    key={`${user.account.id}_${user.id}`}
                    newDesign
                    onSelect={(url) => handleSelectAccount(url)}
                    {...user}
                    route={route}
                  />
                );
              })
            )}
          </AccountsList>
          <DefaultButton
            disabled={!isAccountSelected}
            fullWidth
            id='universal-login-submit-button'
            onClick={handleButtonClick}
            text={t('mfa.sign_in_cta')}
            type='submit'
          />
        </FormWrapper>
      ) : (
        <FormWrapper>
          <LogoWrapper>
            <Logo src={TrainualLogo} />
          </LogoWrapper>
          <H1>{t('which_account_would_you_like')}</H1>
          <AccountsList>
            {isLoading ? (
              <Loader />
            ) : (
              data?.users?.map((user) => (
                <Account
                  href={generateAccountHref(user, route)}
                  key={user.account.id}
                  {...user}
                  route={route}
                />
              ))
            )}
          </AccountsList>
        </FormWrapper>
      )}
    </PublicPage>
  );
};

export default AccountSelector;
