import React from 'react';
import { getPrimaryPreviewColor } from 'saguaro';
import { useTheme } from 'styled-components';

import {
  PalettePickerIcon,
  PalettePickerIconInner,
  PalettePickerTextIcon,
  PalettePickerTextIconInner,
  TextIcon,
} from './styles';
import { PalettePickerTriggerProps } from './types';

const PalettePickerTrigger = ({
  ariaLabel,
  buttonId,
  hex,
  palette,
  type = 'text-color',
  disabled = false,
}: PalettePickerTriggerProps) => {
  const { vars } = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const color = palette ? getPrimaryPreviewColor(palette, vars) : hex;
  return (
    <>
      {type === 'background-color' ? (
        <PalettePickerIcon aria-label={ariaLabel} disabled={disabled}>
          <PalettePickerIconInner color={color} />
        </PalettePickerIcon>
      ) : (
        <PalettePickerTextIcon aria-label={ariaLabel} id={buttonId}>
          <TextIcon name='a' size='xs' weight='regular' />
          <PalettePickerTextIconInner color={color} />
        </PalettePickerTextIcon>
      )}
    </>
  );
};

export default PalettePickerTrigger;
